































import {
  TableColumn,
  TableComponent,
} from '@dnb-open-banking/vue-table-component';
import { FeatureFlagDto } from '@portals/shared/admin/FeatureFlagDto';
import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';
import Loader from '@/components/Loader.vue';
import { fetchFeatureFlags } from '@/service/featureFlagService';

type Data = {
  featureFlags: FeatureFlagDto[];
  loading: boolean;
  create: boolean;
};

export default Vue.extend({
  name: 'list-feature-flags',
  components: {
    Loader,
    DnbIcon,
    TableComponent,
    TableColumn,
  },
  data(): Data {
    return {
      featureFlags: [],
      loading: false,
      create: false,
    };
  },
  mounted() {
    this.loadFeatureFlags();
  },
  methods: {
    async loadFeatureFlags() {
      this.loading = true;
      this.featureFlags = await fetchFeatureFlags();
      this.loading = false;
    },
    getName(name: { [key: string]: string }) {
      if (name) {
        return name;
      }
      return 'invalid';
    },
    getType(type: { [key: string]: string }) {
      if (type) {
        return type.valueOf() === 'everyone' ? 'Everyone' : type;
      }
      return 'invalid';
    },
    onRowClick(row: { data: FeatureFlagDto }) {
      this.$router.push(`/feature-flags/${row.data.id}/edit`);
    },
  },
});
